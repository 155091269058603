import { useCallback } from "react";
import useIframeAdapter from "../../hooks/useIframeAdapter";
import { log, logTable } from "../../lib/logger";
import { useStore } from "../../store/store";
import { FromGameMessage } from "./messages/fromGameMessages";

const useGameMessage = () => {
  const { dispatchGameConnectionMessage } = useStore((s) => s.gameConnection);
  const { sendGameMessageToParent } = useIframeAdapter();
  const dispatchTextChatMessage = useStore(
    (s) => s.textChat.dispatchTextChatMessage
  );
  const dispatchVideoConferenceMessage = useStore(
    (s) => s.videoConference.dispatchVideoConferenceMessage
  );
  const dispatchUiMessage = useStore((s) => s.layout.dispatchUiMessage);
  const dispatchSessionMessage = useStore(
    (s) => s.session.dispatchSessionMessage
  );
  const dispatchSocialMessage = useStore((s) => s.social.dispatchSocialMessage);
  const dispatchProfileMessage = useStore(
    (s) => s.profile.dispatchProfileMessage
  );
  const dispatchLoadLevelControllerMessage = useStore(
    (s) => s.loadLevelController.dispatchLoadLevelControllerMessage
  );
  const dispatchMapMessage = useStore((s) => s.map.dispatchMapMessage);
  const dispatchMediaCaptureMessage = useStore(
    (s) => s.mediaCapture.dispatchMediaCaptureMessage
  );
  const dispatchQuestMessage = useStore((s) => s.quest.dispatchQuestMessage);

  return useCallback(
    (message: FromGameMessage) => {
      // If in iframe, the game message will be forwarded to parent
      sendGameMessageToParent?.(message);
      const { type } = message;

      // Log only the relevant messages
      switch (type) {
        case "PerformanceStats":
        case "NearbyPlayers":
        case "PhotonPlayerConnected":
          break;
        default:
          if (type === "UnrealStateUpdate")
            log("MESSAGE_IN_VERBOSE", `<- GameMessage ${type}:`, message);
          else {
            log("MESSAGE_IN", `<- GameMessage ${type}:`);
            logTable("MESSAGE_IN", message);
          }
          break;
      }

      switch (message.type) {
        // Game connection slice.
        case "PerformanceStats":
        case "NearbyPlayers":
        case "PhotonPlayerConnected":
        case "GameIsReady":
        case "EnterRegion":
        case "ExitRegion":
        case "GameQuiz":
        case "AnalyticsEvent":
        case "ProductSelected":
        case "ItemAdded":
        case "CurrencyChanged":
          dispatchGameConnectionMessage(message);
          break;
        case "LoadingLevelStart":
        case "LoadingLevelEnd":
          dispatchLoadLevelControllerMessage(message);
          break;
        // Text Chat message slice.
        case "ReceivedChatMessage":
        case "OnChatMessageDeleted":
        case "SmartChatAction":
        case "SmartChatEngineReply":
        case "SmartChatSubscriptionUpdate":
          dispatchTextChatMessage(message);
          break;
        // Session Slice
        case "EndSession":
          dispatchSessionMessage(message);
          break;
        // Video Conference Slice
        case "SetIsPresenter":
        case "GetScreenSharingStatus":
        case "ScreenSharing":
          dispatchVideoConferenceMessage(message);
          break;
        // Social Slice
        case "ShowBusinessCard":
        case "Reaction":
          dispatchSocialMessage(message);
          break;
        // Profile Slice
        case "ExternalAssetLoadStatus":
          dispatchProfileMessage(message);
          break;
        // Panels Slice
        case "UiAction":
        case "ActionPanel":
        case "InfoCard":
        case "Poll":
          dispatchUiMessage(message);
          break;
        // Map Slice
        case "ActiveRegion":
          dispatchMapMessage(message);
          break;
        case "MediaCaptureAction":
        case "ShareMedia":
          dispatchMediaCaptureMessage(message);
          break;
        case "QuestsInfo":
        case "QuestProgress":
          dispatchQuestMessage(message);
          break;
        // Cry in your bedroom Slice
        default:
          log("WEBRTC", "Unknown game message: ", message);
          break;
      }
    },
    [
      dispatchMediaCaptureMessage,
      sendGameMessageToParent,
      dispatchGameConnectionMessage,
      dispatchLoadLevelControllerMessage,
      dispatchTextChatMessage,
      dispatchSessionMessage,
      dispatchVideoConferenceMessage,
      dispatchSocialMessage,
      dispatchProfileMessage,
      dispatchUiMessage,
      dispatchMapMessage,
      dispatchQuestMessage,
    ]
  );
};

export default useGameMessage;
