import { useCallback, useEffect, useMemo, useState } from "react";
import { GyaradosSDK } from "@journee-live/gyarados";
import { useGetQuests } from "../../../api/gyaradosHooks";
import { FromGameMessagesSchema } from "../../../core/gameConnection/messages/fromGameMessages";
import { useStore } from "../../../store/store";
import QuestUi from "./Quest.ui";

type Props = {
  slug?: string;
};

export const FilterType = {
  All: "All",
  Active: "Active",
  Completed: "Completed",
  NotStarted: "NotStarted",
} as const;

const QuestLogic: React.FC<Props> = () => {
  const closePanel = useStore((s) => s.layout.closePanel);
  const getQuestsInfo = useStore((s) => s.quest.getQuestsInfo);
  const quests = useStore((s) => s.quest.quests);
  const [isLoadingQuests, setIsLoadingQuests] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<keyof typeof FilterType>(
    FilterType.All
  );

  const allQuests = useGetQuests();
  const [mixedQuests, setMixedQuests] = useState<
    (GyaradosSDK.QuestResponseDto &
      FromGameMessagesSchema["QuestsInfo"]["quests"][number])[]
  >([]);

  // TODO: The settings panel should be managed with the system we talked about long ago.
  useEffect(() => {
    closePanel("settings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadingQuests) return;
    getQuestsInfo();
    setIsLoadingQuests(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadingQuests && quests.length > 0) {
      setIsLoadingQuests(false);
    }

    if (quests.length > 0) {
      const mixedQuests = allQuests
        .map((quest) => {
          const statusQuest = quests.find(
            (statusQ) => statusQ.slug === quest.questId
          );
          if (statusQuest) {
            return {
              ...quest,
              ...statusQuest,
            };
          }
          const state = "Active" as const;
          return {
            ...quest,
            slug: "",
            state,
            currencyCollectedAmount: 0,
            currencyNeededAmount: 0,
          };
        })
        .filter((quest) => {
          if (activeFilter === FilterType.All) {
            return true;
          } else {
            return quest.state === activeFilter;
          }
        });

      // Sort by order in ascending order
      mixedQuests.sort((a, b) => {
        return a.order != null && b.order != null ? a.order - b.order : 0;
      });

      setMixedQuests(mixedQuests);
    }
  }, [quests, isLoadingQuests, allQuests, activeFilter]);

  const handleDismiss = useCallback(() => {
    closePanel("quest");
  }, [closePanel]);

  const questButtonFilters = useMemo(() => {
    return [
      {
        type: FilterType.All,
        active: activeFilter === FilterType.All,
        onClick: () => setActiveFilter(FilterType.All),
      },
      {
        type: FilterType.Active,
        active: activeFilter === FilterType.Active,
        onClick: () => setActiveFilter(FilterType.Active),
      },
      {
        type: FilterType.Completed,
        active: activeFilter === FilterType.Completed,
        onClick: () => setActiveFilter(FilterType.Completed),
      },
      {
        type: FilterType.NotStarted,
        active: activeFilter === FilterType.NotStarted,
        onClick: () => setActiveFilter(FilterType.NotStarted),
      },
    ];
  }, [activeFilter, setActiveFilter]);

  return (
    <QuestUi
      quests={mixedQuests}
      buttonFilters={questButtonFilters}
      handleDismiss={handleDismiss}
      isLoadingQuests={isLoadingQuests}
    />
  );
};

export default QuestLogic;
